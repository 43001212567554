export const locationIconStyles = ({ isDarkHeader, isSmallScreen }: { isDarkHeader: boolean, isSmallScreen: boolean }) => {
  let color;
  if (isSmallScreen) {
    color = 'inherit';
  } else {
    color = isDarkHeader ? 'text.light' : 'text.interactive';
  }

  return {
    display: 'flex',
    alignItems: 'center',
    color,
    verticalAlign: 'middle',
  };
};

export const linkStyles = ({ isLightText, isSmallScreen }: { isLightText: boolean, isSmallScreen: boolean }) => {
  let color;
  if (isSmallScreen) {
    color = 'inherit';
  } else {
    color = isLightText ? 'text.light' : 'text.interactive';
  }

  return {
    color,
    fontWeight: isSmallScreen ? '700' : '500',
    '&:hover': { opacity: 0.8 },
  };
};