import { Icon, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import TAGLink from '@/components/TAGLink';
import { Maybe } from '@/types';

import { linkStyles, locationIconStyles } from './HeaderSimpleFindOfficeLink.styles';

export interface IHeaderSimpleFindOfficeLink {
	locationBarWithOutOfficeTitle?: string;
	locationBarFindOfficeUrl?: Maybe<string>;
	isDarkHeader?: boolean;
}

export default function HeaderSimpleFindOfficeLink({
	locationBarWithOutOfficeTitle,
	locationBarFindOfficeUrl,
	isDarkHeader = false,
}: Readonly<IHeaderSimpleFindOfficeLink>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack direction="row" data-test-id="simple_find_office_link" alignContent="center" spacing={0.5}>
			<Icon>
				<LocationOnOutlinedIcon
					sx={locationIconStyles({ isDarkHeader, isSmallScreen })}
					data-test-id="icon_location"
				/>
			</Icon>

			<TAGLink
				href={locationBarFindOfficeUrl ?? undefined}
				linkSx={{
					textDecoration: 'none',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Typography variant="bodyMediumSemiBold" sx={linkStyles({ isLightText: isDarkHeader, isSmallScreen })}>
					{locationBarWithOutOfficeTitle ?? 'Find a Center'}
				</Typography>
			</TAGLink>
		</Stack>
	);
}
