import { SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
	Asset,
	Maybe,
	Page,
	NavigationLink as TNavigationLink,
	AnalyticsContext,
	BlogDetailsPage,
	FacilityDetailsPage,
	ServiceDetailsPage,
} from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import { resolveEntryLink } from '@/utils/resolveButtonEntryLink/resolveButtonEntryLink';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';

import { getNavItemStyles, getNavItemTypographyVariant, imageStyles, getNavLinkStyles } from './NavigationLink.styles';
export interface INavigationLinkProps extends TNavigationLink {
	isInternal: boolean;
	itemIndex: number;
	linkText?: Maybe<string>;
	pageData?: Maybe<Page | BlogDetailsPage | FacilityDetailsPage | ServiceDetailsPage>;
	externalUrl?: Maybe<string>;
	openInNewTab?: Maybe<boolean>;
	linkIcon?: Maybe<Asset>;
	dataTestId?: string;
	isUtilityNavigation?: boolean;
	disableUnderline?: boolean;
	compactMobileMenuStyle?: boolean;
	eventType?: string;
	navigationType?: string;
	isTextColorLight?: boolean;
	analyticsContext?: Maybe<AnalyticsContext>;
	navLinkSx?: SxProps;
}

interface INavigationLink {
	isInternal: boolean;
	pageData?: Maybe<Page | BlogDetailsPage | FacilityDetailsPage | ServiceDetailsPage>;
	linkText?: Maybe<string>;
	externalUrl?: Maybe<string>;
	analyticsContext?: Maybe<AnalyticsContext>;
}

export function resolveNavLinkText({ linkText, isInternal, pageData, externalUrl }: INavigationLink) {
	if (linkText) {
		return linkText;
	}
	if (isInternal && pageData?.__typename === 'Page') {
		return pageData?.breadcrumbTitle || pageData?.title || '';
	}
	return externalUrl || '';
}

export default function NavigationLink({
	linkText,
	pageData,
	linkIcon,
	itemIndex,
	isInternal,
	externalUrl,
	openInNewTab,
	analyticsContext,
	dataTestId = 'nav_link',
	disableUnderline = false,
	isTextColorLight = false,
	isUtilityNavigation = false,
	compactMobileMenuStyle = false,
	navLinkSx,
}: INavigationLinkProps) {
	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();

	const href = isInternal
		? resolveEntryLink({
				path: pageData?.path || '',
				typeName: pageData?.__typename as string,
				config,
				officeInfo,
				officeLocationInfo,
		  })
		: externalUrl || '#';
	const text = resolveNavLinkText({ linkText, isInternal, pageData, externalUrl });
	const withIcon = !!linkIcon?.url || false;
	const title = (isInternal ? pageData?.title : externalUrl) || undefined;

	const theme = useTheme();
	const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<TAGLink
			title={title}
			href={href || '#'}
			dataTestId={dataTestId}
			analyticsContext={analyticsContext}
			openInNewTab={openInNewTab ?? false}
			linkSx={{
				...getNavLinkStyles(isMobileScreen, itemIndex, isUtilityNavigation, compactMobileMenuStyle),
				...navLinkSx,
			}}
		>
			{withIcon && <img src={linkIcon?.url as string} alt={linkIcon?.description || ''} style={imageStyles} />}
			{!withIcon && (
				<Typography
					variant={getNavItemTypographyVariant(isMobileScreen, isUtilityNavigation)}
					color={isTextColorLight ? 'text.light' : 'text.interactive'}
					sx={getNavItemStyles({
						isMobileScreen,
						isUtilityNavigation,
						withIcon,
						disableUnderline,
						compactMobileMenuStyle,
						isTextColorLight,
					})}
				>
					{text}
				</Typography>
			)}
		</TAGLink>
	);
}
